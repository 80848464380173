import { Carousel } from "./../Service/Carousel.js"
import { Preloader } from "./../Service/Preloader.js"
import { Icons } from "../Service/Icons.js"
import { Animate } from "./../Service/Animate.js"
import { Switch } from "../Service/Switch.js"
import { Counter } from "./../Service/Counter.js"

export class Application
{
    constructor(store)
    {
        this.store = store

        this.store.service.Carousel = new Carousel()
        this.store.service.Preloader = new Preloader()
        this.store.service.Icons = new Icons()
        this.store.service.Animate = new Animate()
        this.store.service.Switch = new Switch()
        this.store.service.Counter = new Counter()
    }

    run()
    {
        this.store.service.Carousel.run()
        this.store.service.Carousel.watch()
        this.store.service.Preloader.watch()
        this.store.service.Icons.run()
        this.store.service.Icons.watch()
        this.store.service.Animate.run()
        this.store.service.Switch.watch()
        this.store.service.Counter.watch()
    }
}
