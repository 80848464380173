import ScrollReveal from "scrollreveal"

export class Animate
{
	elements = document.querySelectorAll('[data-s~="Animate"]')

    pattern = {
        delay: null,
        distance: null,
        duration: null,
        easing: null,
        interval: null,
        opacity: null,
        origin: null,
        rotate: null,
        scale: null,
        cleanup: null,
        container: null,
        desktop: null,
        mobile: null,
        reset: null,
        useDelay: null,
        viewFactor: null,
        viewOffset: null
    }

    config = {}

    run()
    {
        this.elements.forEach(e => {
            this.check(e)
        })
    }

    animate()
    {
        ScrollReveal().reveal(this.target, this.config)
    }

    check(e)
    {
        this.target = e

        Object.keys(this.pattern).forEach(f => {
            if (e.hasAttribute("data-"+f)) {
                this.config[f] = e.getAttribute("data-"+f)
            }
        })

        this.animate()
    }
}
