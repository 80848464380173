export class Counter
{
	counters = document.querySelectorAll('[data-s~="Counter"]')
    config = {
        rootMargin: "0px",
        threshold: 0.25
    }

    constructor()
    {
        this.observer = new IntersectionObserver((e, observer) => {
            this.run(e, observer)
        }, this.config)
    }

    run(es, observer)
    {
        es.forEach((e) => {
			if (e.isIntersecting) {
                this.count(e.target, 5000)
                observer.unobserve(e.target)
			}
		})
    }

    count(e, duration)
    {
        let from = e.getAttribute("data-count-from")
        let to = e.getAttribute("data-count-to")
        let startTime = null
        let currentTime = Date.now()

        const step = (currentTime) => {

            if (!startTime) {
                startTime = currentTime
            }

            const progress = Math.min((currentTime - startTime) / duration, 2)

            e.textContent = Math.floor(progress * (to - from) + from)

            if (progress < 1) {
                window.requestAnimationFrame(step)
            } else if (progress >= 1) {
                e.textContent = to
            }
        }

        window.requestAnimationFrame(step)
    }

    watch()
    {
        this.counters.forEach(e => {
            this.observer.observe(e)
		})
    }
}
